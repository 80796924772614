import * as React from 'react'
import { BanquetProps } from 'banquet-runtime-modules'
import { EcProps } from '@toasttab/ec-banquet-props'
import { AppRouter } from './AppRouter'
import { AppContext } from './AppContext'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { LockLockedIcon } from '@toasttab/buffet-pui-icons'
import { t } from '@local/translations'

export type AppProps = {
  spaProps?: {
    employeeId: string
  }
  ec?: EcProps
} & BanquetProps

export const App = (props?: AppProps) => {
  const user = props?.ec?.user
  return !!user ? (
    <AppContext user={user}>
      {/*React.Suspense is required to resolve the useFeature hook promise. Once this is no longer behind a feature flag, suspense can be removed*/}
      <React.Suspense>
        <AppRouter />
      </React.Suspense>
    </AppContext>
  ) : (
    <EmptyState
      className='p-2'
      icon={<LockLockedIcon accessibility='decorative' />}
      title={t('no-user.title')}
    >
      {t('no-user.description')}
    </EmptyState>
  )
}
