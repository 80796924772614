import {
  track as ecTrack,
  useTracking as useEcTracking
} from '@toasttab/ec-track'

type DataToastTrackIdProps = {
  'data-toast-track-id': TrackId
}

type App = 'employee-benefits-spa'

type DashboardTrackId = 'dashboard'
type DashboardTrackIds =
  | 'group-health'
  | 'manual-deduction'
  | 'legacy'
  | 'vestwell'

export const trackDashboard = (
  actionTrackId: DashboardTrackIds
): DataToastTrackIdProps => {
  return track(`employee-benefits-spa.dashboard.${actionTrackId}`)
}

type ConsentTrackId = 'consent'
type ConsentTrackIds =
  | 'agree'
  | 'cancel'
  | 'close'
  | 'privacy-statement'
  | 'simply-insured-license'
  | 'toast-insurance-license'

export const trackConsent = (
  actionTrackId: ConsentTrackIds
): DataToastTrackIdProps => {
  return track(`employee-benefits-spa.consent.${actionTrackId}`)
}

export type TrackId =
  | `${App}.${DashboardTrackId}.${DashboardTrackIds}`
  | `${App}.${ConsentTrackId}.${ConsentTrackIds}`

const track = (trackId: TrackId): { 'data-toast-track-id': TrackId } =>
  ecTrack(trackId) as DataToastTrackIdProps

export const useTracking = (): { track: (trackId: TrackId) => void } => {
  const { track: ecTrack } = useEcTracking()
  const track = (trackId: TrackId) => {
    ecTrack(trackId)
  }
  return { track }
}
